import { useContext } from 'react'

import { contexto } from './../Context/Context'
import { SwalFire } from '../helpers/SwalFire'
import { toast } from 'react-toastify'
import { cartContext } from '../Context/CartContext'

const BtnCartSumar = ({ item }) => {

  const { addToCart } = useContext(cartContext)

  return (
    <div className='agregarUnidades'>
      <button className='sumar'
        onClick={() => {
          if (item.contador < item.Stock) {
            addToCart(item, 1)
          }
          else {
            // SwalFire('Error', 'El stock actual es insuficiente para agregar más productos','error');
            toast.error('El stock actual es insuficiente para agregar mas productos.', {
              autoClose: 400,
            });
            return false
          }
        }}
      >
        <i className="fa-solid fa-plus"></i>
      </button>
    </div>
  )
}

export default BtnCartSumar