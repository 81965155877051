import { useContext } from 'react'
import { cartContext } from '../Context/CartContext'

const BtnEliminar = ({ item }) => {

    const { deleteItem } = useContext(cartContext)

    return (
        <div>
            <button onClick={() => { deleteItem(item.Alias) }} className='eliminarUnidades'>
                <i className="fa-sharp fa-solid fa-trash-can"></i>
            </button>
        </div>
    )
}

export default BtnEliminar
