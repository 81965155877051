
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddItem from "../Carrito/AddItem";

import { useContext } from 'react';
import { globalContext } from '../Context/GlobalContext';
import { authContext } from '../Context/AuthContext';

export default function TablesCategorias(props) {
    const pais = process.env.REACT_APP_COUNTRY
    const navigate = useNavigate()
    const articulosXCategoria = props.articulosPorCategoria;
    const { grid } = useContext(globalContext);
    const { token } = useContext(authContext);

    //console.log("displayList elegi",displayList);

    return (
        <>
            <div className="table-container">
                <Table hover variant='light'>
                    <tbody>
                        {articulosXCategoria.map((articulo, idx) => {
                            return (
                                <tr key={idx}>
                                    <td className="fondoBlanco">
                                        <img src={articulo.Imagenes && process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(String(articulo.Imagenes.split(',')[0]))}`}
                                            alt="sbox"
                                        />
                                    </td>
                                    <td>
                                        <h6 className='table-name-product' onClick={() => navigate(`/detalle?sku=${articulo.Alias}`)}>{articulo.Nombre}</h6> <br />
                                        <p className="numero-de-parte">
                                            Marca: {articulo.Marca} |  SKU: {articulo.Alias}
                                        </p>
                                        <p className="numero-de-parte">
                                            {articulo.Precio === undefined ? '' : 'Stock: ' + articulo.Stock}
                                            {articulo.Precio === undefined ? '' : ' | Valor: USD ' + articulo.Precio}
                                        </p>
                                        {pais === "PA" ? (
                                            <p className='precios-sin-iva-card'>
                                                {articulo.Precio === undefined ? '' : '* Los precios no incluyen ITBMS'}
                                            </p>
                                        ) : pais === "USA" ? (
                                            null
                                        ) : (
                                            <p className='precios-sin-iva-card'>
                                                {articulo.Precio === undefined ? '' : '* Los precios no incluyen IVA'}
                                            </p>
                                        )}

                                        {/* <p className="precios-sin-iva-table">
                                            {articulo.Precio === undefined ? '' : '* Los precios no incluyen IVA'}
                                        </p> */}
                                    </td>
                                    <td className="table-botonera">
                                        <div className="button-container">
                                            <div className="button-contenedor-principal">
                                                <div className={`price-container`}>
                                                </div>
                                                <div className="logged-buttons">
                                                    <div className="ver-producto-container">
                                                        {!token ? <button className='seeProductButton' onClick={() => navigate(`/detalle?sku=${articulo.Alias}`)}>
                                                            Ver producto
                                                        </button> : <button className='seeProductButtonLogged' onClick={() => navigate(`/detalle?sku=${articulo.Alias}`)}>
                                                            Ver producto
                                                        </button>}
                                                    </div>
                                                    {articulo.Precio !== undefined && (
                                                        <div className="btnTables">
                                                            <AddItem producto={articulo} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
}

