import Table from 'react-bootstrap/Table'
import { useTranslation } from 'react-i18next';

const country = process.env.REACT_APP_COUNTRY;
let montoPais = ""

if (country === "PY") {
    montoPais =
        "GS"
} else if (country === "GT") {
    montoPais =
        "Q"
}
else {
    montoPais =
        "$"
}


export const Taxes = ({ taxPesos, taxDolares, actualizando }) => {
    const [t, i18next] = useTranslation("global")
    return (
        <>
            <h4>{t("impuesto.monto-pais")} {montoPais}</h4>
            <Table>
                <thead>
                    <tr>
                        <th>{t("impuesto.concepto")}</th>
                        <th>{t("impuesto.monto")}</th>
                    </tr>
                </thead>
                <tbody>
                    {taxPesos.map(item => {
                        return (
                            <tr>
                                <td>{Object.keys(item)}</td>
                                <td> {country === 'GT' ? 'Q' : '$'} {Object.values(item)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            <h4>{t("impuesto.monto-dolar")}</h4>
            <Table>
                <thead>
                    <tr>
                        <th>{t("impuesto.concepto")}</th>
                        <th>{t("impuesto.monto")}</th>
                    </tr>
                </thead>
                <tbody>
                    {taxDolares.map(item => {
                        return (
                            <tr>
                                <td>{Object.keys(item)}</td>
                                <td>U$D {Object.values(item)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}