import { AnimationOnScroll } from "react-animation-on-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BannerZebra = () => {
  const country = process.env.REACT_APP_COUNTRY;

  const handleClick = () => {
    let email;
    // Condiciona el correo según el país
    if (country === 'UY') {
      email = "irina_pintos@solutionbox.com.uy";
    } else if (country === 'PY') {
      email = "susana_marecos@solutionbox.com.py";
    } else {
      email = "info@solutionbox.com.ar"; 
    }

    window.location.href = `mailto:${email}`;
  };

  return (
    <div className="">
      <AnimationOnScroll animateIn="animate__fadeIn">
        <LazyLoadImage
          src={process.env.PUBLIC_URL + "banner/Zebra.webp"}
          alt="Imagen marcas"
          className="imagenesBody"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />
      </AnimationOnScroll>
      <p></p>
    </div>
  );
};

export default BannerZebra;
