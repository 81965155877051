import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Buscador.css';
import { useTranslation } from 'react-i18next';
import { getCategorias } from '../../services/getCategorias';

const Buscador = () => {
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [suggestedBrands, setSuggestedBrands] = useState([]);
    const [suggestedCategories, setSuggestedCategories] = useState([]);
    const [categoria, setCategoria] = useState([]);
    const [error, setError] = useState(null);
    const apiProductos = process.env.REACT_APP_API_PRODUCTOS
    const [categoriaLoaded, setCategoriaLoaded] = useState(false); // La uso para validar que las categorias esten o no cargadas, para no colapsar el servidor
    const [fetchingData, setFetchingData] = useState(false);
    const searchInputRef = useRef(null);
    let img = process.env.PUBLIC_URL + '/assets/sin-imagen.png';
    const [userInteracted, setUserInteracted] = useState(false);
    const elementosPorPagina = 7;
    const [t, i18n] = useTranslation("global")
    let debounceTimer;

    const fetchData = async (input) => { //PIDO LOS PRODUCTOS Y LOS GUARDO EN LOCALSTORAGE
        try {
            const [productosRes] = await Promise.all([
                axios.get(`${apiProductos}/info?nombre=${searchInput}&limit=${elementosPorPagina}&offset=0`)
            ]);
            setSuggestedProducts(productosRes.data.articulos);
            localStorage.setItem('productos', JSON.stringify(productosRes.data.articulos));
        } catch (error) {
            console.error(error);
        }
    };

    const fetchDataCategorias = async (retryCount = 0) => {
        try {
            setFetchingData(true);
            const storedCategorias = localStorage.getItem('categorias-stored');
            if (storedCategorias) {
                setCategoria(JSON.parse(storedCategorias));
                setFetchingData(false);
                return;
            }
            // Si no estan almacenadas usa el service de getCategorias
            const categoriasData = await getCategorias();
            setCategoria(categoriasData);
            setFetchingData(false);
        } catch (err) {
            if (retryCount < 3) {
                if (err?.response?.status === 500) {
                    console.log("Error en el servidor.");
                } else if (err?.response?.status === 429) {
                    console.log("Error código 429 demasiados intentos, aguarde.");
                    setTimeout(() => fetchDataCategorias(retryCount + 1), 180000); // 3 minutos
                } else {
                    setError(err);
                    console.error('Error al obtener los datos:', err);
                    setTimeout(() => fetchDataCategorias(retryCount + 1), 180000); // 3 minutos
                }
            } else {
                console.error('Se alcanzó el máximo de intentos. No se pudo obtener los datos:', err);
            }
            setFetchingData(false);
        }
    };

    useEffect(() => {
        if (!categoriaLoaded && categoria.length === 0 && !fetchingData) {
            fetchDataCategorias();
            setCategoriaLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoria, categoriaLoaded, fetchingData]);

    useEffect(() => { //TIENE UN TIMEOUT PARA QUE CUANDO HAGA EL FETCHING DE PRODUCTOS NO SE CARGUE EL SERVIDOR
        const debounceSearch = () => {
            clearTimeout(debounceTimer);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            debounceTimer = setTimeout(() => {
                if (searchInput.length >= 3) {
                    fetchData(searchInput);
                    if (categoria !== null) {
                        const filteredCategories = categoria.filter(category => category.Descripcion.toLowerCase().includes(searchInput.toLowerCase()));
                        setSuggestedCategories(filteredCategories);
                    }
                }
            }, 300); // Esperar 300 milisegundos antes de ejecutar la búsqueda
        };

        debounceSearch();

        return () => {
            clearTimeout(debounceTimer); // Limpiar el temporizador al desmontar el componente
        };
    }, [searchInput]);


    useEffect(() => { //OBTENGO LAS MARCAS Y LAS FILTRO
        const storedBrands = JSON.parse(localStorage.getItem('marca')) || [];
        const filteredBrands = storedBrands.filter(brand => brand.Descripcion.toLowerCase().includes(searchInput.toLowerCase()));
        setSuggestedBrands(filteredBrands);
        // console.log(suggestedBrands);

    }, [searchInput]);

    const closeSuggestions = () => {
        setSuggestedProducts([]);
        setSuggestedBrands([]);
        setSuggestedCategories([]);
    };

    useEffect(() => {
        document.addEventListener('click', closeSuggestions);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('click', closeSuggestions);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleKeyDown = (e) => {
        if (userInteracted && e.key === 'Escape') {
            closeSuggestions();
        }
    };

    const handleSuggestionClick = (suggestion, type) => {
        setSearchInput(suggestion);
        if (type === 'producto') {
            navigateToProduct(suggestion);
        } else if (type === 'marca') {
            navigateToBrand(suggestion);
        } else if (type === 'categoria') {
            navigateToCategory(suggestion)
        }
    };

    const navigateToProduct = (productId) => {
        navigate(`/detalle?sku=${productId}`);
        setSearchInput('');
    };

    const navigateToBrand = (brandCode) => {
        navigate(`/marcas/${brandCode}`);
        setSearchInput('');
    };

    const navigateToCategory = (categoryCode) => {
        navigate(`/categorias/${categoryCode}`);
        setSearchInput('');
    }

    useEffect(() => {
        const handleInputFocus = () => {
            setUserInteracted(true);
        };

        searchInputRef.current.addEventListener('focus', handleInputFocus);

        return () => {
            searchInputRef.current.removeEventListener('focus', handleInputFocus);
        };
    }, []);

    return (
        <form className={`search-form ${searchInput ? 'active' : ''}`}>
            <div className="search-bar-container">
                <div className="search-input-container">
                    <input
                        type="text"
                        className={`search-input ${searchInput ? 'input-active' : ''}`}
                        placeholder={t("buscador.buscador1")}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                        onFocus={() => setUserInteracted(true)}
                        ref={searchInputRef}
                    />
                    <span className="search-icon" >
                        <i className={`fas ${searchInput ? 'fa-times' : 'fa-search'}`}></i>
                    </span>
                </div>
            </div>
            <div className={`suggested-results ${searchInput.length >= 2 ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                {(searchInput.length >= 2 || suggestedCategories.length >= 0) && (
                    <div>
                        {suggestedCategories.map((category, index) => (
                            <div
                                key={index}
                                className="suggested-result"
                                onClick={() => handleSuggestionClick(category.Codigo, 'categoria')}
                            >
                                <div className="suggested-text">
                                    {category.Descripcion} (Categoria)
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {(searchInput.length >= 2 || suggestedBrands.length > 0) && (
                    <div>
                        {suggestedBrands.map((brand, index) => (
                            <div
                                key={index}
                                className="suggested-result"
                                onClick={() => handleSuggestionClick(brand.Codigo, 'marca')}
                            >
                                <div className="suggested-text">
                                    {brand.Descripcion} (Marca)
                                </div>
                            </div>
                        ))}

                        {suggestedProducts.map((product, index) => (
                            <div
                                key={index}
                                className="suggested-result"
                                onClick={() => handleSuggestionClick(product.Alias, 'producto')}
                            >
                                <div className="suggested-text">
                                    <img
                                        src={
                                            product.Imagenes
                                                ? process.env.PUBLIC_URL +
                                                `/articulos/thumbs/${encodeURIComponent(product.Imagenes.split(',')[0])}`
                                                : img
                                        }
                                        alt="sbox"
                                        className="imgThumb"
                                        loading="lazy"
                                    />
                                    {product.Nombre} (Producto)
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </form>
    );
};

export default Buscador;
