import { useState, useContext } from 'react'
import { Button } from 'react-bootstrap';

import BtnPostAdd from './BtnPostAdd'
import { toast } from 'react-toastify';
import { cartContext } from '../Context/CartContext';
import { globalContext } from '../Context/GlobalContext';
import { useTranslation } from 'react-i18next';

const AddItem = ({ producto }) => {

    const { addToCart, cartLocal } = useContext(cartContext)
    const [contador, setContador] = useState(1)
    const { grid } = useContext(globalContext);


    const sumar = () => {
        if (contador < producto.Stock) {
            setContador(contador + 1)
        }
        else {
            return false
        }
    }
    const restar = () => {
        if (contador > 1) {
            setContador(contador - 1)
        }
        else {
            return false
        }
    }

    const itemInCart = cartLocal.length === 0 ? undefined : cartLocal.find(item => item.Alias === producto.Alias);

    const onAdd = () => {
        if (!itemInCart || itemInCart.length === 0) {
            addToCart(producto, contador)
        }
        else if (producto.Stock - itemInCart.contador - contador >= 0) {
            addToCart(producto, contador)
        }
        else {
            //   SwalFire('Error!', 'El stock actual es insuficiente para agregar más productos', 'error')
            toast.error(t("producto.stock-insuficiente"), {
                autoClose: 400
            });
        }
    }
    const [t, i18next] = useTranslation("global")

    if (itemInCart) return <BtnPostAdd producto={producto} />

    else {

        return (
            <div className={`${grid && 'flexGridAddCartAddItem'}`}>
                <div className="counter" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px 0',
                }}>

                    <button className="counter-button minus mesita" onClick={restar} style={{
                        backgroundColor: 'transparent',
                        border: '1px solid red',
                        cursor: 'pointer',
                        fontSize: '1rem',
                        width: '35px',
                        height: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#d90429',
                        transition: 'background-color 0.2s ease-in-out',
                        marginRight: '5px', /* Adjust the spacing between buttons */
                    }}>
                        -
                    </button>

                    <span style={{
                        fontSize: '1rem',
                        margin: '0 10px', /* Adjust the spacing around the counter value */
                    }} className="counter-value">{contador}</span>

                    <button className="counter-button plus" onClick={sumar} style={{
                        backgroundColor: 'transparent',
                        border: '1px solid red',
                        cursor: 'pointer',
                        fontSize: '1rem',
                        width: '35px',
                        height: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#d90429',
                        transition: 'background-color 0.2s ease-in-out',
                        marginLeft: '5px', /* Adjust the spacing between buttons */
                    }}>
                        +
                    </button>
                </div>
                {/* <div className='bntAddMinus' id='divAddToCart'>
                    <button onClick={restar} className='restar'><i className={`fa-sharp fa-solid fa-minus ${grid && 'ml-0'}`}></i></button>
                    <input value={contador} readOnly />
                    <button onClick={sumar} className='sumar'><i className="fa-solid fa-plus"></i></button>
                </div> */}
                <Button variant='success' id='addToCart' onClick={onAdd} className='botonesAll' >{t("producto.agregar-carrito")}</Button>
            </div>
        )
    }
}
export default AddItem