import './CardMarcas.css';
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import AddItem from "../Carrito/AddItem";
import { globalContext } from '../Context/GlobalContext';
import { useTranslation } from 'react-i18next';

export default function CardMarcas(props) {
	const pais = process.env.REACT_APP_COUNTRY


	const navigate = useNavigate()
	const [isMobile, setIsMobile] = useState(false);
	//const articulosXMarca = props.articulo;
	const articulosXMarca = props.articulosXMarca
	const { grid } = useContext(globalContext);
	console.log(articulosXMarca, "articulos por marca")

	const irADetalles = (param, id) => {
		const id_replaced = id.replace(/ç/g, '');
		navigate(`/detalle?sku=${id_replaced}`, {
			state: {
				param,
				id
			}
		});
	}

	// Valido el tamanio de la screen
	const handleResize = () => {
		if (window.innerWidth < 670) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize)
	})

	let img = process.env.PUBLIC_URL + "/assets/sinimagen.gif";

	const [t, i18next] = useTranslation("global")

	console.log(articulosXMarca, "display list")

	return (
		<>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Row>
					{articulosXMarca.map((articulo, idx) => (

						<Col key={idx} style={{ display: "flex", justifyContent: "center" }}>
							<div className='cards-cont' style={{ boxShadow: "0 0 20px #00000034" }}>
								<Card style={{ width: "16rem", border: "none", backgroundColor: "#fff" }}>
									<div style={{ objectFit: "cover", height: "275px", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Card.Img
											variant="top"
											src={
												articulo.Imagenes
													? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(articulo.Imagenes?.split(',')[0])}`
													: img
											}
											style={{ maxWidth: "fit-content" }}
											alt={'solution box'}
										/>
									</div>
									<Card.Body>
										<Card.Title className='nombre-marca-card'>{articulo.Marca}</Card.Title>
										<Card.Text className='nombre-producto-card' onClick={() => irADetalles(articulo, articulo.Alias)}>{articulo.Nombre}</Card.Text>
										<Card.Text className='textoSKU' style={{ textAlign: 'center' }}><b>SKU:</b> {articulo.Alias}</Card.Text>
										<div className='flexPrecioStock'>
											<Card.Text>
												{articulo.Precio === undefined ? '' : 'U$D: ' + articulo.Precio}
											</Card.Text>
											<Card.Text>
												{articulo.Precio === undefined ? '' : 'Stock: ' + articulo.Stock}
											</Card.Text>

										</div>
										{pais === "PA" ? (
											<p className='precios-sin-iva-card'>
												{articulo.Precio === undefined ? '' : '* Los precios no incluyen ITBMS'}
											</p>
										) : pais === "USA" ? (
											null
										) : (
											<p className='precios-sin-iva-card'>
												{articulo.Precio === undefined ? '' : '* Los precios no incluyen IVA'}
											</p>
										)}

										{/* 										<p className='precios-sin-iva-card' >{articulo.Precio === undefined ? '' : `* Los precios no incluyen IVA`}</p>
 */}										<div className={`logged-buttons ${grid && 'flexGrid'}`}>
											<button
												className='seeProductButtonCards'
												onClick={() => irADetalles(articulo, articulo.Alias)}
											>
												{t("producto.ver-producto")}</button>
											<br />
											{articulo.Precio !== undefined
												&&
												<div className="ml-0">
													<AddItem producto={articulo} />
												</div>
											}
										</div>
									</Card.Body>
								</Card>
							</div>
						</Col>
					))}
				</Row>
			</div>
		</>
	);
}
