import React from 'react'
import './Index.css'

const CardDestacado = (props) => {
    return (
        <div>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card custom-card">
                            <img src={props.Imagen} width={200} height={200} alt="Imagen" className="custom-image" style={{ padding: '20px' }} />
                            <div className="custom-content">
                                <h5 className="card-title">{props.Name}</h5>
                                <p className="card-text">{props.Description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardDestacado