import "./indexStyle.css"
import { Column, Container, Galeria, Panel } from "./styles";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Carousel } from 'react-bootstrap'
import Hero from "../Hero/Index";
import Info from "../Info/Info";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ProductoVenta from "../ProductoVenta/ProductoVenta";
import axios from 'axios'
import { authContext } from "../../Context/AuthContext";
import { marcasContext } from "../../Context/MarcasContext";
import { Error } from "../../helpers/Error";
import CardDestacado from "./CardDestacado";
import useObtenerDestacados from "../../../hooks/useFetchDestacados";
import { SpinnerMarcas } from "../../helpers/SpinnerMarcas";
import { useTranslation } from "react-i18next";

const apiProductos = process.env.REACT_APP_API_PRODUCTOS

const Productos = () => {
  const location = useLocation();
  const _sku = new URLSearchParams(location.search).get('sku');
  const sku = encodeURIComponent(_sku + location.hash)
  const [marca, setMarca] = useState({})
  const [load, setLoad] = useState(false)
  const { token } = useContext(authContext)
  const { itemVisto, setItemVisto } = useContext(marcasContext)
  const { destacado, error, irADetalles, isNavigating } = useObtenerDestacados();

  useEffect(() => {
    obtenerDatos();
    setItemVisto({ ...itemVisto, sku })
  }, [sku]); // eslint-disable-line react-hooks/exhaustive-deps 

  const obtenerDatos = async () => {
    await axios.get(`${apiProductos}/detalle?sku=${sku}`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then(res => { setMarca(res.data); setMarca(res.data); setLoad(true) })
      .catch(err => { console.log(err); })
  }

  const imagenesItem = marca.Imagenes?.split(',')
  const array = []
  const [t, i18next] = useTranslation("global")
  let img = process.env.PUBLIC_URL + '/assets/sin-imagen.png';
  
  if (!imagenesItem) {
    array.push(img)
  }
  for (let item in imagenesItem) {
    console.log(imagenesItem[item])
    array.push(imagenesItem[item])
  }

  // Manejo los destacados con el hook

  if (error) return <Error />;
  if (!destacado) return null;
  if (isNavigating) return <SpinnerMarcas />

  // Variable con imagen placeholder para mostrar con el ternario cuando el producto no tiene img cargada

  if (!load) return <SpinnerMarcas text={'Cargando producto'} />
  return (
    <Container>
      <Hero data={marca} />
      <Panel>
        <Column>
          <Galeria>
            <Carousel fade variant="dark" id="carouselDetalles">
              {array.map(item => {
                return (
                  <Carousel.Item className="imgDetalles">
                    <LazyLoadImage
                      className="d-block w-100 "
                      src={marca.Imagenes ? process.env.PUBLIC_URL + "/articulos/" + encodeURIComponent(item) : img}
                      alt='sbox'
                      effect="blur"
                    //className="img_tamano"
                    />
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </Galeria>
          <p className="imagenIlustrativa">{t("producto.imagenes-ilustrativas")}</p>
          <Info data={marca} />
          <div className="relacionados-container">
            <h4 style={{
              marginLeft: '2em', color: '#4a5759'
            }}>{t("producto.productos-interes")}:</h4>
            {destacado.length > 0 ? (
              <div className="map-destacados">
                {destacado.map((dest) => (
                  <div onClick={() => irADetalles(dest, dest.Alias)}>
                    <CardDestacado
                      key={dest.Nombre}
                      Imagen={dest.Imagenes ? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(dest.Imagenes?.split(',')[0])}` : img}
                      Name={dest.Marca}
                      Description={dest.Nombre}
                    /></div>
                ))}
              </div>
            ) : <h5 style={{ marginLeft: '3em', marginTop: '2em' }}>{t("producto.no-existe")}</h5>}
          </div>
        </Column>
        <Column>
          <ProductoVenta datos={marca} />
          <div className="relacionados-container-mobile">
            <h4 style={{
              marginLeft: '2em', color: '#4a5759'
            }}>{t("producto.productos-interes")}:</h4>
            {destacado.length > 0 ? (
              <div className="map-destacados-mobile">
                {destacado.map((dest) => (
                  <div onClick={() => irADetalles(dest, dest.Alias)}>
                    <CardDestacado
                      key={dest.Nombre}
                      Imagen={dest.Imagenes ? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(dest.Imagenes?.split(',')[0])}` : img}
                      Name={dest.Marca}
                      Description={dest.Nombre}
                    /></div>
                ))}
              </div>
            ) : <h5 style={{ marginLeft: '2em', marginTop: '2em' }}>{t("producto.no-existe")}.</h5>}
          </div>
        </Column>
      </Panel>
    </Container>
  );
};

export default Productos;

