import React, { useContext, useState } from 'react'
import './ConfiguradorHpe.css'
import NewLogin from '../Login/NewLogin';
import IframeHpe from '../IframeHpe/IframeHpe';
import { authContext } from '../Context/AuthContext';


export default function ConfiguradorHpe() {

	const { user } = useContext(authContext);
	const [isAuthenticated, setIsAuthenticated] = useState(Boolean(user));

	const handleClick = () => {
		const country = process.env.REACT_APP_COUNTRY;
		let host = '';
		let token = '';

		switch (country) {
			case "UY":
				host = window.atob('RFNPVVkxMTEwMA==');
				token = window.atob('NUYwMUEwMUZFQTgyNDI2NUJGMUE5Njk3RkUxMzMzREM=');
				break;
			case "CR":
				host = window.atob('RFNPQ1IxMTEwMg==');
				token = window.atob('QzYzMUQyQTA2NjEzNDkyMDg4MjNEMDZEQUNBQzc1MkQ');
				break;
			case "USA":
				host = window.atob('RFNPTUkzMzEyNg==');
				token = window.atob('NTNhNTM5MmUzMWI5ODAwMjQ2YTFlYTcwZTRhNDNlNzY=');
				break;
			case "DO":
				host = window.atob('RFNPRE8xMTExMw==');
				token = window.atob('NGJkYzkxM2ZiN2I0OTZmMTVjNTc2NTc3ZmY5MjdkMzA');
				break;
			default:
				// TOKEN POR DEFECTO USA
				host = window.atob('RFNPTUkzMzEyNg==');
				token = window.atob('NTNhNTM5MmUzMWI5ODAwMjQ2YTFlYTcwZTRhNDNlNzY=');
				break;
		}

		document.formIquote.host.value = host;
		document.formIquote.token.value = token;
		document.formIquote.submit();
	}


	let refValue = "";
	const country = process.env.REACT_APP_COUNTRY;

	switch (country) {
		case "CR":
			refValue = "https://www.solutionbox.cr";
			break;
		case "USA":
			refValue = "https://www.solutionboxusa.com";
			break;
		case "DO":
			refValue = "https://www.solutionbox.com.do";
			break;
		case "UY":
			refValue = "https://www.solutionbox.com.uy";
			break;
		default:
			refValue = "https://www.solutionbox.cr"; // Valor por defecto si el país no coincide con ninguno de los casos anteriores.
			break;
	}

	// !user && setIsAuthenticated(Boolean(user));


	return (
		<>
			{user ?
				<div className="btnEnviarIQ" name="btnEnviarIQ">
					<div className='contenedorTituloBanner my-4'>
						<div className='columna1'>
							<h2 className='h2HPE'>COTIZAR AHORA</h2>
							<p>En su nueva versión, iQuote 2.0 permite cotizar servidores, almacenamiento y Networking de manera más rápida, más confiable, más fácil de usar, es decir, con menos clics con una sola pantalla. </p>
							<a className='btnIquote' onClick={handleClick} href="#">Accede al configurador</a>
						</div>
						<div className='imgHpe my-5'>
							<img /* className='imgHpe' */ alt='HPE IQUOTE' src={`${process.env.PUBLIC_URL}/imagenes/HPE-logo-2.png`} loading='lazy' onClick={handleClick} />
						</div>
						<form className='d-none' name="formIquote" action="https://iquote.hpe.com/aspx/gatekeeper.aspx" target="_blank" method="post">
							<input type="hidden" name="host" value="" />
							<input type="hidden" name="token" value="" />
							<input type="hidden" name="clearSession" value="Y" />
							<input type="hidden" id="mfr" name="mfr" value="HPE" />
							<input type="hidden" name="uEmail" value={user.Email} />
							<input type="hidden" name="uName" value={`${user.Nombre} ${user.Apellido}`} />
							<input type="hidden" name="CName" value={user.NomCliente} />
							<input type="hidden" name="cPCode" value={user.Domicilio_facturacion.Codigo_postal} />
							<input type="hidden" name="cAccountNum" value={parseInt(user.Id)} />
							<input type="hidden" name="ref" value={refValue} /> {/* Usar la variable refValue aquí */}
						</form>
					</div>
					<IframeHpe />
				</div>
				:
				<>
					<h3 className='mb-5'>You need to be logged in to see the HPE configurator</h3>
					<NewLogin />
					<IframeHpe />
				</>
			}
		</>
	)
}
