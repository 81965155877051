import './Table.css';
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddItem from "../Carrito/AddItem";
import sinimagen from './sinimagen.gif';
import { useContext } from 'react';
import { authContext } from '../Context/AuthContext';
import { useTranslation } from 'react-i18next';

export default function Tables(props) {
  const pais = process.env.REACT_APP_COUNTRY

  const navigate = useNavigate()
  // const articulosXMarca = props.articulo;
  const articulosXMarca = props.articulosXMarca
  const { token } = useContext(authContext);

  console.log(articulosXMarca, "articulos por marca")

  const [t, i18next] = useTranslation("global")

  return (
    <>
      <div className="table-container">
        <Table hover variant='light'>
          <tbody>
            {articulosXMarca.map((articulo, idx) => {
              return (
                <tr key={idx}>
                  <td className="fondoBlanco">
                    <img src={articulo.Imagenes ? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(String(articulo.Imagenes.split(',')[0]))}` : sinimagen}
                      alt="sbox"
                    />
                  </td>
                  <td>
                    <h6 className='table-name-product' onClick={() => navigate(`/detalle?sku=${articulo.Alias}`)}>{articulo.Nombre}</h6> <br />
                    <p className="numero-de-parte">
                      {articulo.Marca} |  N° de parte: {articulo.Alias}
                    </p>
                    <p className="numero-de-parte">
                      {articulo.Precio === undefined ? '' : 'Stock: ' + articulo.Stock}
                      {articulo.Precio === undefined ? '' : ' | Valor: USD ' + articulo.Precio}
                    </p>
                    <p className="precios-sin-iva-table">
                      {articulo.Precio === undefined || pais === "USA" ? '' : `* Los precios no incluyen ${pais === "PA" ? "ITBMS" : "IVA"}`}
                    </p>
                  </td>
                  <td className="table-botonera">
                    <div className="button-container">
                      <div className="button-contenedor-principal">
                        <div className={`price-container`}></div>
                        <div className="logged-buttons">
                          <div className="ver-producto-container">
                            {!token ? <button className='seeProductButton' onClick={() => navigate(`/detalle?sku=${articulo.Alias}`)}>
                              {t("producto.ver-producto")}
                            </button> : <button className='seeProductButtonLogged' onClick={() => navigate(`/detalle?sku=${articulo.Alias}`)}>
                              {t("producto.ver-producto")}
                            </button>}
                          </div>
                          {articulo.Precio !== undefined && (
                            <div className="btnTables">
                              <AddItem producto={articulo} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

