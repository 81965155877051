import { useContext, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import axios from 'axios';
import { Button } from "react-bootstrap"
import BtnCartSumar from './BtnCartSumar'
import BtnCartRestar from './BtnCartRestar'
import BtnEliminar from './BtnEliminar'
import { Unauthorized } from '../helpers/Unauthorized'
import { SpinnerGif } from '../helpers/SpinnerGif';
import './Carrito.css'
import { cartContext } from '../Context/CartContext';
import { authContext } from '../Context/AuthContext';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const urlApiPedidos = process.env.REACT_APP_API_PEDIDOS

const Cart = () => {

  const { cartLocal, setPrecompra } = useContext(cartContext)
  const { token, user, desloguearse } = useContext(authContext)
  const navigate = useNavigate()
  const totalPrecio = cartLocal.reduce((total, producto) => total + producto.Precio * producto.contador, 0)
  const [items, setItems] = useState()
  const [loading, setLoading] = useState(false)
  const [t, i18n] = useTranslation("global")
  const country = process.env.REACT_APP_COUNTRY;

  const mapearData = () => {
    const array = []
    for (let i = 0; i < cartLocal.length; i++) {
      let data = {
        Alias: cartLocal[i].Alias,
        Precio: cartLocal[i].Precio,
        Cantidad: cartLocal[i].contador,
        Moneda: cartLocal[i].Moneda_Signo,
      }
      array.push(data)
    }
    setItems(array)
  }

  useEffect(() => {
    mapearData()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  const procesarPrecompra = () => {

    let condicion_pago = {
      Codigo: user.Condicion_Pago.Codigo,
      Descripcion: user.Condicion_Pago.Descripcion
    }

    let tipo_de_entrega = {
      Codigo: user.Tipo_entrega.Codigo,
      Descripcion: user.Tipo_entrega.Descripcion
    }


    console.log('este es el tipo de entrega inicial al procesar la precomopra', tipo_de_entrega)

    let dir_entrega;

    if (tipo_de_entrega.Descripcion === 'ENTREGA') {
      dir_entrega = {
        Domicilio: user.Domicilio_entrega.Domicilio,
        Localidad: user.Domicilio_entrega.Localidad,
        codigoPostal: user.Domicilio_entrega.Codigo_postal,
        Codigo_Prov: user.Domicilio_entrega.Codigo_Prov,
        Pais: user.Domicilio_entrega.Pais
      }
    } else {
      dir_entrega = null

    }

    let Precompra = {
      items: items,
      cond_pago: condicion_pago,
      tipo_entrega: tipo_de_entrega,
      Direccion_entrega: dir_entrega
    }


    console.log(Precompra, 'esta es la precompra que se postea desde el cart con la data del usuario')

    axios.post(`${urlApiPedidos}/proforma`, { precompra: Precompra }, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(setLoading(true))
      .then(res => {
        if (res.status === 401) {
          setLoading(false);
          desloguearse()
        }
        setPrecompra(res.data);
        //console.log("Rtas " + res.data.Subtotal_Dolares.SubTotal);
        //console.log('RES', res);
        navigate('/checkout')
      })
      .catch(err => {
        setLoading(false); console.log('ERROR CART', err);
      })
  }

  if (!token) return <Unauthorized />

  if (totalPrecio === 0) {
    return (
      <div className='carritoVacio'>
        <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy'></img>

        <h1>{t("carrito.carrito-vacio")}</h1>
        <br />
        <hr />
        <h5 className='mb-3'>{t("carrito.explora-catalogo")}</h5>

        <Button variant="dark" className="btnVolver" onClick={() => navigate(`/`)}>Ir al inicio</Button>
      </div>
    )
  }

  if (loading) return <SpinnerGif text={t("carrito.procesar-compra")} />


  let taxSucursal = "";

  if (country === "PA") {
    taxSucursal = "ITBMS";
  } else {
    taxSucursal = "IVA";
  }

  return (
    <div>
      <img src={process.env.PUBLIC_URL + "banner/bannercompra.png"} alt="Imagen Carrito" className='imagenesBody' loading='lazy'></img>
      <div className='cartRenderizado'>
        <h1>{t("pedidos.orden")}</h1>
        <Table responsive className='tableCart desktop-table'>
          <thead>
            <tr>
              <th>
                <span>{t("pedidos.cantidad")}</span>
                <span>Cant.</span>
              </th>
              <th></th>
              <th>{t("pedidos.descripcion")}</th>
              <th>{t("pedidos.numero-parte")}</th>
              <th>Marca</th>
              {country !== "USA" ? <th>{t("pedidos.unitario")} (Sin {taxSucursal}) </th> : <th>{t("pedidos.unitario")}</th>}
              {country !== "USA" ? <th>{t("pedidos.total")} (Sin {taxSucursal}) </th> : <th>{t("pedidos.total")}</th>}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cartLocal.map(item => {
              return (
                <tr key={item.Alias}>
                  <td>{item.contador}</td>
                  <td className='modificarUnidades'><BtnCartSumar item={item} /><BtnCartRestar item={item} /></td>
                  <td><Link to={`/detalle?sku=${item.Alias}`} className='linkItem'>{item.Nombre}</Link></td>
                  <td>{item.Alias}</td>
                  <td>{item.Marca}</td>
                  <td>U$S {item.Precio}</td>
                  <td>U$S {(item.Precio * item.contador).toFixed(2)}</td>
                  <td><BtnEliminar item={item} /></td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr className='tableFooter'>
              <td colSpan={4}></td>


              <td></td>
              <td></td>
              <td>{country !== "USA" ? `Precio total (Sin ${taxSucursal})` : "Total"}</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={4}></td>


              <td></td>
              <td></td>
              <td><b>U$S {(totalPrecio).toFixed(2)}</b></td>
              <td></td>
            </tr>
            {country !== "USA" &&
              <tr>
                <td>
                  {t("carrito.calculo-1")} {taxSucursal} {t("carrito.calculo-2")}
                </td>
              </tr>}
          </tfoot>
        </Table>

        <Table responsive className='tableCart mobile-table'>
          <tbody>
            {cartLocal.map(item => {
              return (
                <tr key={item.Alias}>
                  <td>
                    <div className="d-flex flex-column align-items-start">
                      <span><strong>Cantidad</strong>: {item.contador}</span>
                      <span className='modificarUnidades'>
                        <BtnCartSumar item={item} />
                        <BtnCartRestar item={item} />
                      </span>
                      <span><strong>Producto</strong>:
                        <Link to={`/detalle?sku=${item.Alias}`} className='linkItem'>
                          {item.Nombre}
                        </Link>
                      </span>
                      <span><strong>N° de parte</strong>: {item.Alias}</span>
                      <span><strong>Marca</strong>: {item.Marca}</span>
                      <span><strong>Precio unitario (Sin {taxSucursal})</strong>: U$S {item.Precio}</span>
                      <span><strong>{country !== "USA" ? `Precio total (Sin ${taxSucursal})` : "Total"}</strong>: <strong>U$S {(item.Precio * item.contador).toFixed(2)}</strong></span>
                      <span><BtnEliminar item={item} /></span>
                    </div>
                  </td>
                </tr>
              )
            })}
            {country !== "USA" && <tr>
              <td>
                {t("carrito.calculo-1")} {taxSucursal} {t("carrito.calculo-2")}
              </td>
            </tr>}
          </tbody>
        </Table>
        <div className='btnFinalizarCompra'>
          <button onClick={procesarPrecompra} className='btnConfirmarCompra'>
            <i className="fa-sharp fa-solid fa-basket-shopping "></i>
            {t("pedidos.confirm")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Cart