import "./RmaForm.css";
import { BsCardText, BsFillPersonFill } from "react-icons/bs";
import { useRef, useState } from "react";
import { AiOutlineMail, AiOutlineNumber, AiOutlineFieldNumber } from "react-icons/ai";
import { FaHandsHelping } from 'react-icons/fa';
import { MdDateRange, MdFormatListNumberedRtl } from 'react-icons/md';
import { BiSolidBusiness, BiCube } from 'react-icons/bi';
import { FaNewspaper } from 'react-icons/fa'
import { AiOutlinePhone } from "react-icons/ai";
import { GiTicket } from 'react-icons/gi';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { data } from "../../config/config.js"
import { useTranslation } from "react-i18next";

const RmaForm = () => {
    const [usuarioValido, setUsuarioValido] = useState(false);
    const apiRma = process.env.REACT_APP_API_CONTACTO;

    const onChange = () => {
        if (captcha.current.getValue()) {
            console.log("El usuario no es un robot");
            setUsuarioValido(true);
        }
    };

    const [values, setValues] = useState({
        nombre: "",
        razonSocial: "",
        factura: "",
        fechaFactura: "",
        email: "",
        vendedor: "",
        marca: "",
        problema: "",
        telefono: "",
        Serie: "",
        macId: "",
        Cliente: "",
        rmaGrandstream: "",
    });

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;

        let newValue;

        if (type === "number") {
            // Solo permitir números
            newValue = value.replace(/[^0-9]/g, "");
        } else if (
            type === "text" &&
            name !== "macId" &&
            name !== "rmaGrandstream" &&
            name !== "Serie"
        ) {
            // Solo permitir letras
            newValue = value.replace(/[^A-Za-z\s]/g, "");
        } else if (type === "email") {
            // Solo permitir letras
            newValue = value.replace(/[^A-Za-z0-9@._-]/g, "");
        } else if (
            name === "macId" &&
            name === "rmaGrandstream" &&
            name === "Serie"
        ) {
            // Solo permitir letras
            newValue = value.replace(/^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g, "");
        } else {
            // Otros tipos de entrada, mantener el valor sin cambios
            newValue = value;
        }

        setValues({
            ...values,
            [name]: newValue,
        });
    };

    const handleKeyPress = (e) => {
        const keyCode = e.which || e.keyCode;
        const isValidKey =
            (keyCode >= 48 && keyCode <= 57) || // Números del 0 al 9
            (keyCode >= 96 && keyCode <= 105) || // Números del teclado numérico
            keyCode === 8 || // Tecla de retroceso (Backspace)
            keyCode === 9; // Tecla Tab

        if (!isValidKey) {
            e.preventDefault();
        }
    };

    const handleKeyPressSpecialCharacters = (e) => {
        const keyCode = e.which || e.keyCode;
        const isValidKey =
            (keyCode >= 48 && keyCode <= 57) || // Números del 0 al 9
            (keyCode >= 65 && keyCode <= 90) || // Letras mayúsculas A-Z
            (keyCode >= 97 && keyCode <= 122) || // Letras minúsculas a-z
            keyCode === 8 || // Tecla de retroceso (Backspace)
            keyCode === 9 || // Tecla Tab
            keyCode === 32 || // Tecla espacio
            (keyCode >= 96 && keyCode <= 105); // Números del teclado numérico

        const forbiddenChars = ["{", "}", "/", "!", "[", "]"];
        const inputValue = String.fromCharCode(keyCode);
        const isCharForbidden = forbiddenChars.includes(inputValue);

        if (!isValidKey || isCharForbidden) {
            e.preventDefault();
        }
    };

    const captcha = useRef(null);

    const handleSubmit = (e) => {
        if (captcha.current.getValue()) {
            Swal.fire({
                icon: "success",
                title: "Mensaje enviado correctamente!",
                text: "Muchas gracias por su mensaje, nos estaremos comunicando a la brevedad",
                timer: 3500, // 3 y medio milisegundos
                showConfirmButton: false,
            }).then(function () {
                window.location = "/";
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Debes realizar la verificacion de seguridad!",
            });
            e.preventDefault();
            console.log("acepta el captcha");
        }
    };

    const [t, i18next] = useTranslation("global")

    return (
        <div className="content">
            <section className="section-login-register">
                <br />
                <div className="form-box-register">
                    <div className="form-value-register">
                        <br />
                        <h1 className="logo">
                            {t("rma.titulo-form1")} <span>{t("rma.titulo-form2")}</span>
                        </h1>
                        <form
                            action={`${apiRma}/rma`}
                            method="POST"
                            onSubmit={handleSubmit}
                        >
                            <p className="text-register-title">
                                * {t("rma.p-registro-usuario")}
                            </p>

                            <div className="input-box-login-register">
                                <BsFillPersonFill
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    type="text"
                                    onChange={handleInputChange}
                                    name="nombre"
                                    value={values.nombre}
                                    required />
                                <label htmlFor="">{t("formulario.label-1")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <BiSolidBusiness
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    type="text"
                                    onChange={handleInputChange}
                                    name="razonSocial"
                                    value={values.razonSocial}
                                    required />
                                <label htmlFor="">{t("formulario.label-5")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <AiOutlineFieldNumber
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    name="Cliente"
                                    value={values.Cliente}
                                    type="number"
                                    onKeyDown={handleKeyPress}
                                    required />
                                <label htmlFor="">{t("formulario.label-6")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <AiOutlinePhone
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    name="telefono"
                                    value={values.telefono}
                                    onKeyDown={handleKeyPress}
                                    type="number"
                                    required />
                                <label htmlFor="">{t("formulario.label-7")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <AiOutlineMail
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    name="email"
                                    value={values.email}
                                    type="email"
                                    required />
                                <label htmlFor="">{t("formulario.label-2")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <FaNewspaper
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    name="factura"
                                    value={values.factura}
                                    onKeyDown={handleKeyPressSpecialCharacters}
                                    type="number"
                                    required />
                                <label htmlFor="">{t("formulario.factura")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <MdDateRange
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    name="fechaFactura"
                                    value={values.fechaFactura}
                                    type="date"
                                    required />
                                <label htmlFor="">{t("formulario.fecha-factura")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <FaHandsHelping
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    name="vendedor"
                                    value={values.vendedor}
                                    type="text"
                                    required />
                                <label htmlFor="">{t("formulario.vendedor")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <BiCube
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    name="marca"
                                    value={values.marca}
                                    type="text"
                                    required />
                                <label htmlFor="">{t("formulario.marca-producto")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <MdFormatListNumberedRtl
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyPressSpecialCharacters}
                                    name="Serie"
                                    value={values.Serie}
                                    type="text"
                                    required />
                                <label htmlFor="">{t("formulario.numero-serie")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <AiOutlineNumber
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyPressSpecialCharacters}
                                    name="macId"
                                    value={values.macId}
                                    type="text"
                                    required />
                                <label htmlFor="">{t("formulario.mac-id")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <GiTicket
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyPressSpecialCharacters}
                                    name="rmaGrandstream"
                                    value={values.rmaGrandstream !== undefined ? values.rmaGrandstream : null}
                                    type="text"
                                />
                                <label htmlFor="">{t("formulario.ticket-aprobado")}</label>
                            </div>

                            <div className="input-box-login-register">
                                <BsCardText
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ef233c'
                                    }}
                                    className="icon-login"
                                />
                                <input
                                    className="white-text-input"
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyPressSpecialCharacters}
                                    name="problema"
                                    value={values.problema}
                                    rows="1"
                                    type="text"
                                    required />
                                <label htmlFor="">{t("formulario.problema")}</label>
                            </div>

                            <div className="captcha-container">
                                <ReCAPTCHA
                                    onChange={onChange}
                                    ref={captcha}
                                    sitekey={data.sitekey}
                                />
                            </div>

                            {!usuarioValido && (
                                <button
                                    disabled
                                    type="submit"
                                    className="button-login-screen"
                                    id="botonSubmit"
                                >
                                    {t("formulario.btn-enviar")}
                                </button>
                            )}
                            {usuarioValido && (
                                <button
                                    type="submit"
                                    className="button-login-screen"
                                    id="botonSubmit"
                                >
                                    {t("formulario.btn-enviar")}
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default RmaForm;
