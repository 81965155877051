import { useContext } from 'react'
import { contexto } from './../Context/Context'
import { cartContext } from '../Context/CartContext'

const BtnCartRestar = ({ item }) => {

  const { addToCart } = useContext(cartContext)

  return (
    <div>
      <button className='restar'
        onClick={() => {
          if (item.contador > 1) {
            addToCart(item, -1)
          }
          else {
            return false
          }
        }}
      >
        <i className="fa-sharp fa-solid fa-minus"></i>
      </button>
    </div>
  )
}

export default BtnCartRestar