import { lazy, Suspense, useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { SpinnerGif } from '../helpers/SpinnerGif';
import Slider from '../Slider/Slider';
import './Imagenes.css';
import { SliderMarcas } from '../SliderMarcas/SliderMarcas';
import { cartContext } from '../Context/CartContext';
import { authContext } from '../Context/AuthContext';
import { Link } from 'react-router-dom';
import { BtnPromociones } from '../BtnPromociones/BtnPromociones';
import OfertaBanner from '../OfertaBanner/OfertaBanner';
import { ButtonWebCat } from '../ButtonWebCat/ButtonWebCat';
import { FooterMapa } from '../FooterMapa/FooterMapa';
import BannerZebra from '../BannerZebra/BannerLenovo';
import BannerCL from '../BannersBody/BannerCL';
import BannerHibrido from '../BannersBody/BannerHibrido';
import { CarruselMarcas } from '../CarruselMarcas/CarruselMarcas';
import BannerMicrositioHuawei from '../BannersBody/BannerMicrositioHuawei';

const Highlighters = lazy(() => import('./Highlighters'));
const Redes = lazy(() => import('../Redes/Redes'));
const BannerHuawei = lazy(() => import('../BannersBody/BannerHuawei'));
const BannerLenovo = lazy(() => import('../BannerLenovo/BannerLenovo'));

export const HomePage = () => {

  const country = process.env.REACT_APP_COUNTRY;

  const { getCartByUser } = useContext(cartContext);
  const { token } = useContext(authContext);

  useEffect(() => {
    if (!token) return false;
    getCartByUser();
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  /* const btnWebinars = process.env.PUBLIC_URL + "/imagenes/capacitacionesYWebinars.png";
  const btnCatalogo = process.env.PUBLIC_URL + "/imagenes/catalogo.png" */

  return (
    <div className="background">
      <Slider />

      {/* {country !== 'CL' &&
        <div className='Container'>
          <div className='contWebinarCatalogo'>
            <Link to="/capacitaciones"><img src={btnWebinars} alt="" className='btnWebinars' /></Link>
            <Link to="/catalogo"><img src={btnCatalogo} alt="" className='btnCatalogo' /></Link>
          </div>
        </div>
      } */}
      <ButtonWebCat />

      <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
        <Container></Container>
      </Suspense>

      <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
        <BtnPromociones />
      </Suspense>

      {country === 'UY' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <Highlighters />
        </Suspense>
      }

      <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
        <OfertaBanner />
      </Suspense>

      {country !== 'USA' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerMicrositioHuawei />
        </Suspense>}

      {country !== 'UY' && country !== "USA" && country !== "PY" && country !== "CL" &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerLenovo />
        </Suspense>}

      {country === 'PY' && country !== "CL" &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerZebra />
        </Suspense>
      }

      {country === 'UY' && country !== "CL" &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerZebra />
        </Suspense>
      }

      {country !== 'UY' && country !== "PY" && country !== "CL" && country !== "DO" && country !== "HN" && country !== "GT" && country !== "CR" &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerHuawei />
        </Suspense>}

      {country === 'DO' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerHibrido />
        </Suspense>}

      {country === 'HN' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerHibrido />
        </Suspense>}

      {country === 'GT' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerHibrido />
        </Suspense>}

      {country === 'CL' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerCL />
        </Suspense>}

      {country === 'CR' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <BannerHibrido />
        </Suspense>}


      {/* <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
        <SliderMarcas />
      </Suspense> */}


      {country === 'USA' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <CarruselMarcas />
        </Suspense>}

      {country === 'CR' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <CarruselMarcas />
        </Suspense>}

      {country === 'DO' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <CarruselMarcas />
        </Suspense>}

      {country === 'GT' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <CarruselMarcas />
        </Suspense>}

      {country === 'HN' &&
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <CarruselMarcas />
        </Suspense>}

      <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
        <FooterMapa />
      </Suspense>

    </div>
  )
};