import "./Stepper.css";
import React, { useContext, useState } from "react";
import PdfViewer from "./PdfViewer";

import { globalContext } from "../Context/GlobalContext";
import { RegistroUsuario } from "./RegistroUsuario";
import { useTranslation } from "react-i18next";

function Stepper() {
  const [step, setStep] = useState(1);
  const [agreed, setAgreed] = useState(false);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleAgree = () => {
    setAgreed(!agreed);
  };

  const steps = ["Leer y aceptar el PDF", "Datos cliente"];

  const circles = steps.map((_, index) => (
    <div key={index} className={`circle ${step > index ? "active" : ""}`}>
      {index + 1}
    </div>
  ));

  const { colorTheme } = useContext(globalContext)

  const [t, i18] = useTranslation("global")

  return (
    <>
      <div className="circulitos">
        <div className="circulazo">
          {circles}
        </div>
      </div>
      {step === 1 && (
        <>
          <h2 className='circulitos-h2'>{t("registroUser.step1-h2")}</h2>
          <div className="acuerdoUsuario">
            <PdfViewer />
          </div>
          <div className="agreeButton">
            <input
              className="check"
              type="checkbox"
              checked={agreed}
              onChange={handleAgree}
            />

            <label className={`text-${colorTheme === 'dark' ? 'light' : 'dark'}`}>{t("registroUser.step1-checkbox")}</label>
            <br />
            <button disabled={!agreed} onClick={handleNext} className="bg-boton mt-3 btn-next">
              {t("registroUser.step1-siguiente")}
            </button>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <h2 className="circulitos-h2">{t("registroUser.step2-h2")}</h2>
          <RegistroUsuario />
          <div className="backButtonContainer">
            <div>
              <button className="btn-back" onClick={handlePrev} >{t("registroUser.step2-volver")}</button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Stepper;

