import axios from "axios";
import { Table, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

import './Profile.css'
import { authContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const apiPedidos = process.env.REACT_APP_API_PEDIDOS

export const DetallesCompraRender = ({ compras, cargarComprobante, handleFileChange, file }) => {

    const navigate = useNavigate();
    const { token } = useContext(authContext);

    const verPdf = () => {
        axios.get(`${apiPedidos}/orden_web/compra/${compras.id_compra}/transferencia`, { // Ver esto, da error endpoint no encontrado 
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => window.open(URL.createObjectURL(res.data)))
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    toast.error('No se ha encontrado el comprobante.', { autoClose: 2000 });
                } else {
                    console.error('Error al obtener el PDF:', err);
                }
            });
    }

    return (
        <div>
            <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading="lazy"></img>

            <div className="divDetallesCompra">
                <div>
                    <h3>Detalle del pedido {compras.Pedido_Nro}/01</h3>
                </div>
                <h4 className="divDetallesCompra">Datos del pedido</h4>
                <Table className='tablaHistorial'>
                    <thead>
                        <tr>
                            <th>Creada</th>
                            <th>Modificada</th>
                            <th>Pagado</th>
                            <th>Precio total sin IVA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {`${new Date(compras.Fecha).toLocaleDateString()}
                            (${new Date(compras.Fecha).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})`}
                            </td>
                            <td>
                                {`${new Date(compras.Fecha).toLocaleDateString()}
                            (${new Date(compras.Fecha).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})`}
                            </td>

                            <td>{(compras.Estado).replace('_', ' ')}</td>
                            <td>U$D {compras.Importe}</td>
                        </tr>
                    </tbody>
                </Table>
                {/* <h4 className="divDetallesCompra">Datos del pago</h4> */}
                {compras.id_compra && compras.id_compra !== "" && (
                    <>
                        <h4 className="divDetallesCompra">Datos del pago</h4>
                        <Table className="tablaPagos">
                            <thead>
                                <tr>
                                    <th>Comprobante de pago</th>
                                    {compras.Fecha ? <th>Fecha de pago</th> : null}

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="enviarComprobante">
                                        {compras.archivo_transf === 'null' || compras.archivo_transf === null
                                            ?
                                            <form
                                                id="formEnviarComprobante"
                                                enctype="multipart/form-data"
                                                onSubmit={cargarComprobante}
                                            >
                                                <input
                                                    class="form-control" name="imagenPago" type="file" id="imagenPago" value={file.imagenPago} accept=".pdf, .png, .jpg, .jpeg"
                                                    onChange={handleFileChange}
                                                >
                                                </input>
                                                {file.imagenPago
                                                    ? <Button type="submit">Enviar</Button>
                                                    : ""
                                                }
                                            </form>
                                            :
                                            <div id="divVerPdf">
                                                {compras.id_compra && compras.id_compra !== "" && (
                                                    <Button onClick={verPdf} variant='secondary'>
                                                        Ver archivo
                                                    </Button>
                                                )}
                                                <span>{(compras.Comprobante).split('/transferencias/')[1]}</span>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {
                                            !compras.Fecha
                                                ? null
                                                : `${new Date(compras.Fecha).toLocaleDateString()}`
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </>
                )}
                <h4 className="divDetallesCompra">Listado de productos</h4>
                <Table className='tablaHistorialItems'>
                    <thead>
                        <tr>
                            <th>SKU</th>
                            <th>Cantidad</th>
                            <th>Precio Unitario sin IVA</th>
                            <th>Precio total sin IVA</th>
                        </tr>
                    </thead>
                    {compras.Items.map(item => {
                        return (
                            <tbody key={item.Alias}>
                                <tr>
                                    <td>{item.Alias}</td>
                                    <td>{item.Cantidad}</td>
                                    <td>{item.Percio}</td>
                                    <td>{item.Percio * item.Cantidad}</td>
                                </tr>
                            </tbody>
                        )
                    })}
                </Table>
                <Button variant="success" onClick={() => navigate('/profile')}>Volver a compras</Button>
            </div>
        </div>
    )
}