import { createContext, useState } from "react";

import { LocalStorage } from "../Data/LocalStorage";

export const globalContext = createContext();

const { Provider } = globalContext;

const GlobalContext = (props) => {
    const [colorTheme, setColorTheme] = LocalStorage("darkMode", "light");
    const [grid, setGrid] = useState(false);
    const [darkMode, setDarkMode] = useState(
        colorTheme === "light" ? false : true
    );

    const handleDisplayGrid = () => {
        setGrid(true);
    };

    const handleDisplayList = () => {
        setGrid(false);
    };

    const contextValue = {
        colorTheme,
        setColorTheme,
        darkMode,
        setDarkMode,
        grid,
        setGrid,
        handleDisplayGrid,
        handleDisplayList,
    };

    return <Provider value={contextValue}>{props.children}</Provider>;
};

export default GlobalContext;
